import style from './nativeListItem.module.css'
import React, {useEffect, useRef} from "react";

const NativeListItem = ({onClick, title, avatar, subtitle, suffix}) => {
    const titleDiv = useRef();
    const el = useRef();

    useEffect(() => {
        const {style} = el.current;
        const { offsetWidth, offsetLeft }  = titleDiv.current;
        style.setProperty('--x-pos', `${offsetLeft}px`);

    }, []);
    return <div ref={el} onClick={onClick} className={style.item}>
        {avatar ? <img  src={avatar} width={40} height={40} style={{borderRadius: '50%'}} alt=""/> : null}
        <div>
            <div ref={titleDiv} className={style.itemTitle}>
                {title}
            </div>
            <div className={style.itemSubtitle}>
                {subtitle}
            </div>
        </div>
        <div className={style.itemSuffix}>
            {suffix}
        </div>
    </div>
}

export default NativeListItem;
