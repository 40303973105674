import ReactDOM from 'react-dom/client';
import React, {useEffect, useState} from "react";
import NativeInput from "./NativeInput/NativeInput";
import NativeListItem from "./NativeListItem/NativeListItem";
import {Hearts} from "react-loader-spinner";
import * as Sentry from "@sentry/react";
import {BottomSheet} from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css'

Sentry.init({
    dsn: "https://d1bb674a74ae1353f3e4f2ac7f2942e1@o4503993548603392.ingest.sentry.io/4506385380147200",
    integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay()
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

const Pay = () => {
    const [comment, setComment] = useState('')
    const [banks, setBanks] = useState([])
    const [payScreen, setPayScreen] = useState()
    const [query, setQuery] = useState('')
    const [isLoading, setLoading] = useState(true)
    const [amount, setAmount] = useState(null);

    const buttonPayCode = window.Telegram.WebApp?.initDataUnsafe?.start_param || false;

    const buttons = [
        {id: 1, text: '👍', displayAmount: 50, displayCurrency: '₽'},
    ]

    const [selectedButton, setSelectedButton] = useState(buttons[0]);

    const [showPhoneModal, setShowPhoneModal] = useState(false);


    const confirmAmount = async () => {
        if (amount !== selectedButton.displayAmount) {
            await loadPaymentScreen(buttonPayCode.split('_')[1], amount)
        }
    }
    const onChangeAmount = (amount) => {
        if (amount > payScreen.maxAmount) {
            setAmount(payScreen.maxAmount);
            return;
        }
        if (amount < 0) {
            setAmount(0);
            return;
        }
        setAmount(amount);
    }

    useEffect(() => {
        if (showPhoneModal) {
            window?.Telegram?.WebApp?.MainButton.show();
        } else {
            window?.Telegram?.WebApp?.MainButton.hide();
        }
    }, [showPhoneModal])

    const loadPaymentScreen = async (buttonId, amount) => {
        setLoading(true);
        const r = await axios.get(`/api/buttons/${buttonId}`, {params: {amount}});
        setPayScreen(r.data)
        setSelectedButton(r.data.selected)
        setAmount(r.data.selected.displayAmount)
        setLoading(false)
        if(!r.data.user.phone) {
            setShowPhoneModal(true);
        } else{
            setShowPhoneModal(false);
        }
    }

    const selectButton = (button) => {
        setSelectedButton(button)
        window?.Telegram?.WebApp?.HapticFeedback.selectionChanged()
    }

    useEffect(() => {
        if (!window.Telegram.WebApp?.isVersionAtLeast(6.9)) {
            alert('Обновите приложение Telegram')
            return;
        }

        window.Telegram.WebApp.expand()

        if (!buttonPayCode) {
            window.Telegram.WebApp?.showPopup({
                title: `Произошла ошибка`,
                message: `Неверная платежная ссылка`,
                buttons: [
                    {type: 'default', text: 'Закрыть'},
                ]
            }, (button) => {
                window.Telegram.WebApp.close()
            });
        }
        loadPaymentScreen(buttonPayCode.split('_')[1])
        try {
            window?.Telegram?.WebApp?.setHeaderColor('#EFEFF4')
        } catch (e) {

        }
        window?.Telegram?.WebApp?.MainButton.setText('Продолжить')
        window?.Telegram?.WebApp?.MainButton.onClick(() => {
            window.Telegram.WebApp.requestContact(async (status, res) => {
                if (status) {
                    window?.Telegram?.WebApp?.MainButton.showProgress(true);
                    await axios.post('/api/verifyPhone', {payload: res.response})
                    await loadPaymentScreen(buttonPayCode.split('_')[1])
                    //window?.Telegram?.WebApp?.MainButton.hide();
                }
            });
        })
    }, []);


    return isLoading ? <div className={'w-screen flex flex-col  h-100 justify-center items-center'}>
        <div>
            <Hearts
                height="80"
                width="80"
                color="#0394FF"
                ariaLabel="hearts-loading"
                visible={true}
            />
        </div>
        <p className={'caption'}>Загружаем ссылки на оплату</p>
    </div> : <div style={{padding: 10}}>
        <div className="flex">
            <img src={'/logo_advanced.svg'} style={{width: 200, padding: 10}}/>
        </div>
        {window.Telegram.WebApp?.isVersionAtLeast(6.7) ? <a className={'text-small block mt-1 p-2'} onClick={() => {
            window.Telegram.WebApp.switchInlineQuery(' ', ['users', 'channels', 'groups'])
        }}>
            Нравится сервис? <span className={'text-blue-700'}>Разместите свой пост с донатами. </span>
        </a> : null}

        <div className={'p-2 text-3xl text-bold rounded-sm'}>
            <div className={'inline-block p-2'}>
                <input
                    //onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                    onBlur={() => confirmAmount()} inputMode={'numeric'}
                    style={{width: amount.toString().length + 'ch'}} value={amount}
                    className={'bg-transparent amount__input'}
                    onChange={(e) => onChangeAmount(e.target.value)}
                    type="text"/> {selectedButton?.displayCurrency}
                <span className={'ml-2'}>
                       {selectedButton?.text}
                </span>

            </div>
            {/*<div className={'ml-2'} style={{background: 'rgb(27 156 252)', display: 'inline-flex', height: 30, justifyContent: 'center', alignItems: 'center', padding: 5, borderRadius: 10, fontSize: 14, color: 'white'}}>*/}
            {/*    /!*<img style={{height: 15}} className={'mr-1'} src="/assets/edit-icon.svg" alt="edit"/>*!/*/}
            {/*    Произвольная сумма*/}
            {/*</div>*/}
        </div>

        <div className="caption">Сообщение автору</div>
        <textarea maxLength={256} onChange={(e) => {
            setComment(e.target.value)
        }} style={{
            width: '100%',
            border: 'none',
            borderRadius: 12,
            resize: 'none',
            padding: '10px 16px',
            height: 100,
            outline: 'none'
        }} placeholder={'Спасибо за контент'}></textarea>
        {/*<div className={'grid grid-cols-2 p-1 mt-2 text-center gap-2'}>*/}
        {/*    {payScreen?.buttons.map((button) => <div onClick={() => loadPaymentScreen(button.id)}*/}
        {/*                                             className={'p-3 rounded-sm reaction_button ' + (selectedButton?.id === button.id ? 'selected' : '')}>*/}
        {/*        {button.text} ({button.displayAmount} {button.displayCurrency})*/}
        {/*    </div>)}*/}

        {/*    /!*<div className={'p-3  rounded-sm reaction_button'}>*!/*/}
        {/*    /!*    Другая сумма*!/*/}
        {/*    /!*</div>*!/*/}
        {/*</div>*/}

        {payScreen.message?.length ? <div className="caption mt-2">{payScreen.message}</div> : null}
        {payScreen?.availableMethods?.length ? <div className="caption mt-2">Выберите способ оплаты</div> : null}
        <div className={'bg-white rounded-tg'}>
            {/*<NativeInput onChange={(e) => {*/}
            {/*    setQuery(e.target.value)*/}
            {/*}} onFocus={() => {*/}
            {/*}} onBlur={() => {*/}
            {/*}} placeholder={'Поиск'}/>*/}
            {payScreen?.availableMethods.filter((method) => !query ? true : method.title.toLowerCase().includes(query.toLowerCase())).map(b =>
                <NativeListItem suffix={<img src="/assets/chevron-right.svg" alt="right"/>} title={b.titleForWeb}
                                avatar={b.icon} subtitle={b.description}
                                onClick={async () => {
                                    window?.Telegram?.WebApp?.HapticFeedback.selectionChanged()
                                    window.Telegram.WebApp.openLink(b.link)
                                    await axios.post(`/api/transaction/${b.transaction_id}/comment`, {
                                        comment
                                    })
                                    window.Telegram.WebApp.close()
                                }}
                                key={b.scheme}/>)}
        </div>
        <p className={'p-2'}>Продолжая использование, вы принимаете условия
        </p>

        <BottomSheet open={showPhoneModal}>
            <div style={{padding: 20}}>
                <h4 className={'font-bold text-2xl'} style={{ fontSize: 16, padding: 0, marginBottom: 5}}>{window.Telegram.WebApp?.initDataUnsafe?.user?.first_name}, добро пожаловать</h4>
                <p className={'text-sm'}>
                    Продолжая использование сервиса, вы принимаете условия <a className={'text-blue-600 outline-none'}
                                                                              target={'_blank'}
                                                                              href="https://donatelike.tech/oferta.pdf">оферты</a>
                </p>
                <a className={'block text-blue-400 text-sm mt-1 outline-none'} onClick={() => {
                    alert('Если кнопка не работает, обновите приложение до последней версии или попробуйте сделать это с мобильного телефона.')
                }} href="#">Возникла проблема?</a>
            </div>
        </BottomSheet>


    </div>
}

export default Pay;

if (document.getElementById('pay')) {
    window.axios.defaults.headers.common['User'] = window.Telegram.WebApp.initData
    //  window.axios.defaults.headers.common['User'] = 'user=%7B%22id%22%3A168406277%2C%22first_name%22%3A%22Anton%22%2C%22last_name%22%3A%22Osipov%22%2C%22username%22%3A%22antonosipoff%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=7785031082436097919&chat_type=private&auth_date=1701332465&hash=abf3163be09da09a0e3ab1fc0065d1a8c453580c50ab5ddbacde740e643b1401'
    ReactDOM.createRoot(document.getElementById('pay')).render(<Pay/>)
}
